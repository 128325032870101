import ExpandAndRetractX from '../../common/utils/expandAndRetractX';
import ZoomIn from '../../common/utils/zoomIn';
import Images from './images';
import { Homepage } from '@/typings/types';

type ShowcaseWrapperProps = {
  showcase: Homepage['showcase'];
};

const ShowcaseWrapper = ({ showcase }: ShowcaseWrapperProps) => {
  return (
    <section className={'w-full pb-24'}>
      <ExpandAndRetractX
        duration={500}
        className={'absolute z-10 h-[190px] w-full bg-white'}
      ></ExpandAndRetractX>
      <ZoomIn
        zoom={0.9}
        triggerOnce
        className={'relative mt-10 h-[190px] w-full bg-middle-border bg-repeat-x'}
      ></ZoomIn>
      <Images images={showcase.images} />
    </section>
  );
};

export default ShowcaseWrapper;
