import Image from 'next/image';
import { Homepage } from '@/typings/types';

type ImagesProps = {
  images: Homepage['showcase']['images'];
};

const Images = ({ images }: ImagesProps) => {
  return (
    <div
      className={
        'mx-auto grid w-11/12 grid-cols-1 gap-y-10 lg:w-full lg:grid-flow-row lg:grid-cols-3 lg:gap-2'
      }
    >
      {images.map((image) => {
        const { url, alternativeText, placeholder } = image?.image?.data?.attributes ?? {};
        return (
          url && (
            <div key={image?.id} className={'relative h-[475px] w-full'}>
              <Image
                src={`${process.env.NEXT_PUBLIC_STRAPI_URL}${url}?resize=475x355`}
                fill
                className={'object-cover'}
                alt={alternativeText ?? ''}
                placeholder={placeholder ? 'blur' : 'empty'}
                blurDataURL={placeholder ?? ''}
              />
            </div>
          )
        );
      })}
    </div>
  );
};

export default Images;
